import React, { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { motion, useAnimation } from 'framer-motion'

const isSSR = typeof window == 'undefined'

const FadeInView = ({ children }) => {
  const controls = useAnimation()
  const [ref, inView] = useInView({ threshold: 0.4 })

  useEffect(() => {
    // SEO Server Side Render Show Content
    if (!isSSR) {
      controls.start('hidden')
    }
  }, [])

  useEffect(() => {
    if (inView) {
      controls.start('visible')
    }
  }, [controls, inView])

  return (
    <motion.div
      data-animation="fadeIn"
      ref={ref}
      animate={controls}
      initial={'seo'}
      transition={{ duration: 0.3, delay: 0.2, ease: 'easeInOut' }}
      variants={{
        seo: {},
        hidden: { opacity: 0, y: '10%' },
        visible: { opacity: 1, y: 0 },
      }}
    >
      {children}
    </motion.div>
  )
}

export default FadeInView
